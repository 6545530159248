@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body{
    margin:0px;
    padding: 0px;
    background-color: #8ccde0;
    font-family: 'Lato', sans-serif;
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}
.logo img{
    width: 120px;
    position: fixed; /* or 'position: absolute;' */
  margin-top: -5%;
  left: 5px;
}
#main{
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./images/medback.png');
    position: relative;
    z-index: 0;
}
#main::before{
    position: absolute;
    content: "";
    background: -moz-radial-gradient(center, ellipse cover, rgba(89, 173, 230, 0.38) 0%, rgba(32, 65, 109, 0.6) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(96, 183, 218, 0.38) 0%, rgba(32, 65, 109, 0.6) 100%);
    background: radial-gradient(ellipse at center, rgba(67, 125, 179, 0.38) 0%, rgba(32, 65, 109, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
#Treatment::before{
    position: absolute;
    content: "";
    background: -moz-radial-gradient(center, ellipse cover, rgba(89, 173, 230, 0.38) 0%, rgba(32, 65, 109, 0.6) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(96, 183, 218, 0.38) 0%, rgba(32, 65, 109, 0.6) 100%);
    background: radial-gradient(ellipse at center, rgba(67, 125, 179, 0.38) 0%, rgba(32, 65, 109, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: rgb(212, 235, 238);
    transition: 0.6s ease-in-out;
}

nav.active {
    box-shadow: 5px 10px 30px rgba(113, 149, 196, 0.6);
    background-color: rgba(152, 175, 206, 0.6);
}

nav ul {
    display: flex;
}

.active {
    background-color: rgb(0, 140, 255);
    color: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 61, 175, 0.411);
    transition: all ease 0.2s;
}

.active:hover {
    background-color: #dfd9d9;
    color: #ffffff;
    box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
    transition: all ease 0.2s;
}

nav ul li {
    position: relative; /* Add this line to position dropdown content */
}

nav ul li a {
    font-family: 'Lato';
    height: 40px;
    line-height: 43px;
    margin: 3px;
    padding: 0px 22px;
    display: flex;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #0c0c0c;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}

nav ul li a:hover {
    background-color: #008cff;
    color: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 63, 197, 0.336);
    transition: all ease 0.2s;
}

nav .menu-btn,
.menu-icon {
    display: none;


}


.name {
    text-align: center;
    font-family: 'Montserrat';
    width: 900px;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -55%);
}

.name span {
    color: #FF1414;
}

.name .details {
    font-size: 23px;
    color: #c5c5c5;
}

.name h2 {
    font-family: 'Lato';
    font-size: 2.7rem;
    margin: 0px;
    letter-spacing: 2px;
    color: #ffffff;
}

.name h1 {
    font-family: 'Lato';
    font-size: 5rem;
    margin: 0px;
    letter-spacing: 2px;
    color: #ffffff;
}



.header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 40%;
}
.header-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #008cff;
    box-shadow: 5px 10px 30px rgba(0, 80, 184, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.header-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #0099ff;
}




#services {
    width: 100%;
    height: 150vh;
    box-sizing: border-box;
    font-family: 'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    background-size: cover;
    background-image: url('./images/img11.jpg');
    position: relative;
}

#services::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    filter: blur(8px);
    z-index: -1;
}

.service-header {
    color: rgb(0, 1, 80);
    text-align: center;
    z-index: 1;
}

.a-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    z-index: 1;
}

.service-card {
    background: rgb(145, 210, 226);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    padding: 20px;
    text-align: center;
}

.service-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.service-card h2 {
    font-size: 1.5em;
    margin: 15px 0;
}

.service-card p {
    font-size: 1em;
    color: #666;
    margin: 10px 0;
}




.send_btn{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#028ac9;
}

#services h1{
    color: #03103d;
    animation: glow 2s infinite;
    font-weight: bolder;
  }
  
  @keyframes glow {
    0%, 100% {
      text-shadow: 0 0 5px #ffffff;
    }
    50% {
      text-shadow: 0 0 20px #ffffff;
    }
  }
.a-container{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #ffffff;
    animation: glow 2s infinite;
}

@keyframes glow {
  0%, 100% {
    text-shadow: 0 0 5px #ffffff;
  }
  50% {
    text-shadow: 0 0 20px #ffffff;
  }
}
.a-box{
    background: linear-gradient(4deg, rgba(174,191,207,1) 0%, rgba(81,167,118,1) 100%);

    width:250px;
    height: 340px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img{
    width: 100%;
    height: 140%;

}
.a-b-img img{
    padding: 15px;
    margin-top: 40px;
    width:100%;
    height: 50%;

}
.a-box:hover{
    border: 1px solid #007dd1;
    box-shadow: 2px 2px 12px rgba(1, 53, 167, 0.445);
}
.a-b-text{
    width: 100%;
    height: 40%;
    background-color:#3ebb1f;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text h2{
    color: #fff;
}
.a-b-text p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1.1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 80%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text p:hover{
    color:#ffffff;
}
.a-b-text a{
    margin-top:15px ;
}
#presentaion{
    padding-top: 30px;
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url(images/offer.png); */
    text-align: center;
}

.header-heading{
    text-align: Left;
	font-family:'Lato';
	width: 600px;
	position: absolute;
    left: 25%;
	top: 50%;
	transform: translate(-50%,-55%);
}
.header-heading span {
    color: #ffffff;
    animation: glow 2s infinite;
  }
  
  @keyframes glow {
    0%, 100% {
      text-shadow: 0 0 5px #ffffff;
    }
    50% {
      text-shadow: 0 0 20px #ffffff;
    }
  }
.header-heading .details{
    font-size: 20px;
    color: #ffffff;
}

.header-heading .details{
    font-size: 1.1rem;
   
}
.header-heading h1{
	font-family:'Lato';
	font-size: 4rem;
	margin:0px;
	letter-spacing: 2px;
	color:#00455a;
    animation: glow 2s infinite;
}
@keyframes glow {
    0%, 100% {
      text-shadow: 0 0 5px #00c3ff;
    }
    50% {
      text-shadow: 0 0 20px #00b7ff;
    }
  }
.header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 35%;
}
.header-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #0897da;
    box-shadow: 5px 10px 30px rgba(2, 15, 197, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.header-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #009edd;
}


.dentist-image {
    width: 500px; /* Adjust the width as needed */
    height: 400px; /* Adjust the height as needed */
    
  }
  .dentist-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .slider-container {
    width: 38%; /* Adjust the width as needed */
    margin: 0 auto;
  }
  
  
/* About Us Section */
.about-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
  }
  
  .about-image {
    width: 100%; /* Make sure the image container takes the full width */
    display: flex;
    justify-content: center; /* Center the image */
    margin-bottom: 20px;
  }
  
  .about-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .about-text {
    text-align: center; /* Center text within the about-text container */
    padding: 20px;
    width: 100%;
  }
  
  .about-text h1 {
    color: #0014c9;
    font-weight: bold;
    margin-bottom: 20px;
    animation: glow 2s infinite;

  }
  
  .about-text p,
  .about-text ul {
    text-align: left;
    font-size: 18px;
    line-height: 1.6;
    color: #2c3e50;
    font-style: italic; /* Apply italic style */
  }
  
  .about-text ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .about-text h2 {
    text-align: left;
    color: #2c3e50;
    font-weight: bold;
    margin-top: 30px;
  }
  
  /* Section Styling */
  .section {
    display: flex;
    flex-wrap: wrap;
    align-items: center; /* Center items vertically within each section */
    margin-bottom: 40px;
  }
  
  .section-image {
    flex: 1 1 30%;
    max-width: 200px;
    margin-right: 20px;
  }
  
  .section-text {
    flex: 1 1 65%;
  }
  
  .section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .section ul,
  .section p {
    margin: 0;
  }
  
  
  

.footer{
    background-color:rgb(6, 76, 113);
    width: 100%;
    text-align: center;
    color: #706e6e;
    
}

.about-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#028ac9;
}
.about-text button:hover{
    box-shadow: 5px 10px 30px rgba(1, 86, 243, 0.336);
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image{
    width: 50%;
}
.about-image img{
  width: 500px;
}

#contact{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#contact h1{
    color: #030192;
    font-size: 3rem;
    animation: glow 3s infinite;

}
#contact form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
    width:100%;
    height:50px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff2d;
    color: #ffffff;
    border-radius: 5px;
}
#contact form textarea{
    height: 150px;
}
#contact form input[type="submit"]{
    height: 45px;
    background: linear-gradient(90deg, #FF1414 20%,#f70000);
    color: #ffffff;
    text-transform: uppercase;
}
.contact-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.contact-card {
    flex: 1;
    max-width: calc(33.33% - 20px);
    margin: 5px;
    min-width: 280px;
    height: 300px;
    padding: 20px;
    background-color: #ffffff2d;
    display: flex;
    flex-direction: column;
    justify-content:flex-start; /* Center content both horizontally and vertically */
    align-items: center;
    font-size: small;
}

.contact-card i {
    font-size: 36px; /* Increase icon size */
    margin-bottom: 20px; /* Add spacing between icon and heading */
}

.contact-card h2 {
    text-align: center;
    margin-top: 0; /* Remove default margin for h2 */
}

/* Adjust breakpoints as needed */
@media (max-width: 768px) {
    .contact-card {
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .contact-card {
        max-width: calc(100% - 20px);
        min-width: 220px;
        height: 250px;
    }
}



@media(max-width:620px){
    #contact form{
        width:90%;
    }
}

@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .name h2{
        font-size: 1.5rem;
    }
    .name h1{
        font-size: 3rem;
    }
    .name .details{
        font-size: 1rem;
    }
    .header-heading{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .header-heading h2{
        font-size: 1.5rem;
    }
    .header-heading h1{
        font-size: 3rem;
    }
    .header-heading .details{
        font-size: 1rem;
    }
	.about-image img{
		height:400px;
    }
    #services{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-box{
		flex-grow: 1;
	}
    .a-b-img img{
		object-fit:contain;
    }
    
    

}

@media(max-width:970px){
	#main{
		height: 650px;
	}
	.name{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    .header-heading{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	.about-image{
        display:none;
    }
    .header-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
    .header-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
	#about{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        
        margin-top: 0px;
	}
	.about-text{
        width: 90%;
	}
	.about-text h1{
		font-size:4rem;
    }
    .about-text p{
        width: 100%;
    }
    #services{
        margin-bottom: 100px;
    }
}
@media(max-width:600px){
	.name{
		width:60%;
    }
    .header-heading{
		width:60%;
    }
}

@media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .header-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#81d1e6;
            border-bottom:4px solid #0096db;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .a-box{
                width: 100%;
                height:400px;
            }
        }

        #info {
            width: 100%;
            max-width: 1200px;
            box-sizing: border-box;
            font-family: 'Lato';
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 40px;
            background-size: cover;
            background-image: url('./images/image18.jpg');
            position: relative;
        }
        
        #info::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: inherit;
            background-size: cover;
            filter: blur(8px);
            z-index: -1;
        }
        
        .team-header {
            color: rgb(1, 57, 131);
            animation: glow 2s infinite;

            text-align: center;
            z-index: 1;
        }
        
        .team-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
          }
        
          .team-card {
            background-color: #f9f9f9;
            border: 1px solid #ddd;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            padding: 20px;
            text-align: center;
            width: 100%;
            max-width: 300px;
            box-sizing: border-box;
          }
        
          .team-card img {
            max-width: 100%;
            height: auto;
            border-radius: 50%;
          }
        
        .team-card h2 {
            font-size: 1.5em;
            margin: 15px 0;
        }
        
        .team-card p {
            font-size: 1em;
            color: #666;
            margin: 10px 0;
        }
        @media (max-width: 768px) {
            .team-card {
              width: 80%;
            }
          }
          
          @media (max-width: 480px) {
            .team-card {
              width: 100%;
            }
          
        .company-info {
            color: rgb(51, 50, 50);
            text-align: center;
            margin-top: -30px;
            padding: 20px;
            font-style: italic;
            z-index: 1;
            font: size 2em;;
        }
        

    }